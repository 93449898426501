import { Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import Layout from '../components/layout'

const NotFoundPage = ({ data }) => {
  const posts = get(data, 'allFile.posts')

  return (
    <Layout title="404" hideBreadcrumb>
      <div class="error-page-inner">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="inner">
                <h1 class="title theme-gradient">404!</h1>
                <h3 class="sub-title">Page non trouvée</h3>
                <span>La page que vous recherchez n'a pu être trouvée.</span>
                <div class="error-button">
                  <Link class="rn-button-style--2 btn_solid" to="/">
                    Retour à l'accueil
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
